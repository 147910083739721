import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Paranas, Samar used to be notorious for timber poaching. Men knew the perils of the job, but they had no choice because food had to be served on the table. Fortunately, hope prevailed when the Tour Guides and Boat Operators for River Protection and Environmental Development Organization (TORPEDO) was formed. Watch Eugene as he recounts how tour guiding gave his community a chance to earn a living from doing something good.
				`

function Carousell() {
	return (
		<>
		<Story heading="EUGENE IGDALINO" description={description} ytID="Y28RyUtPNRc" />
		</>
	)
}

export default Carousell
