import React, {useState} from 'react'
import Navbar from './nav'
import HeroSection from './hero'
import VideoSection from './videopromo'
import DescriptionSection from './description'
import CarousellSection from './carousell'
import FooterSection from './footer'
import FooterSectionB from './footerB'
// import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade'
import Loading from './loading'
import { Preload } from 'react-preload'
import { carouselData } from './data/carouseldata'

function App() {
	var loadingIndicator = <Loading />
	// PRELOADER
	// TODO: add img from carouseldata.js. check the format below
	var images = [
		carouselData[0].img,
		carouselData[1].img,
		carouselData[2].img,
		carouselData[3].img,
		carouselData[4].img,
		carouselData[5].img,
		carouselData[6].img,
		carouselData[7].img,
		carouselData[8].img,
		carouselData[9].img,
		carouselData[10].img,
		carouselData[11].img,
		carouselData[12].img,
		carouselData[13].img
	]
	const [ mount, setMount ] = useState(true)

	return (
		<div className="App">
	 	    <Navbar /> 
			<HeroSection />
			<VideoSection  id="section-video"/>
			<DescriptionSection />
			<Preload
				loadingIndicator={loadingIndicator}
				images={images}
				autoResolveDelay={3000}
				resolveOnError={true}
				mountChildren={true}
			>
				{mount &&
				<CarousellSection id="section-carousel" />
				}
			</Preload>
			<FooterSection />
			{/* <FooterSectionB /> */}
		</div>
	)
}

export default App
