/**
 * Template for stories
 * v 1.0
 * BBDO Guerrero 
 */

import React from 'react'
import styled from 'styled-components'
import tstyled from 'tachyons-components'
import Nav from '../nav'

// Master CSS - Styled components
const StoryContainer = styled.section`
	background-color: #33347e;
	color: white;
`
const Description = styled.p`
	font-family: Asap, sans-serif;
	font-size: 15pt;
`

// Tachyons styling 
const StoryResponsiveContainer = tstyled('div')`flex-l w-100 pt6-l mt5-l pt4-m`
const VideoContainer = tstyled('div')`h5 h-auto-l w-50-l pt0-l mt0-l pl6-l`
const Video = tstyled('iframe')`w-100 w-100-l h-100 h-60-l pt4 mt3 `
const ProfileContainer = tstyled('div')`pv5 tc w-50-l pv0-l pt6-l mt5-l`
const Heading = styled.h2`font-family: Barabara;`
const DescriptionContainer = tstyled('div')` w-80 pl5 ml2 tl pl6-m ml3-m w-50-l pl6-l ml4-l`
const Back = tstyled('a')`underline white`

/**
 * 
 * @param {ytID, heading, description} props 
 */

function Story(props) {
	return (
		<StoryContainer>
			<Nav />
			<StoryResponsiveContainer>
				<VideoContainer>
					<Video
						src={"https://www.youtube.com/embed/" +  props.ytID }
						frameborder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					/>
				</VideoContainer>
				<ProfileContainer>
					<Heading> {props.heading}</Heading>
					<DescriptionContainer>
						<Description>
							{props.description}
						</Description>
						<Description>
							<Back href="/">Back</Back>
						</Description>
					</DescriptionContainer>
				</ProfileContainer>
			</StoryResponsiveContainer>
		</StoryContainer>
	)
}

export default Story
