import React from 'react'
import styled from 'styled-components'
import device from './device'
import tstyled from 'tachyons-components'
import Jump from 'react-reveal/Jump'

const HeroContainer = styled.section`
	background: url('/images/COLLAGE_WEAVE_FA2.jpg') center;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	align-items: center;
	max-height: 100%;
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
	zoom: 100%;
	font-family: 'Barabara';
	color: white;

	@media screen and ${device.mobileL} {
		width: 100vw;
		height: 100%;
		background: url('/images/weave-mobile.jpg') center;
		background-attachment: scroll;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

`
const HeroRowContainerText = tstyled('div')`
w-100 pt2 pt4-m mt3-m pt3-l mt3-l ml3 ml6-l pl2 pl6-l
`
const HeroTextH = tstyled('h1')`f6 f3-m f2-l `
const HeroTextH2 = tstyled('h1')`f2 f-5-m  f-6-l tl-l nt1 `
const ImageContainer = tstyled('div')`w-100 tc pt6 mt4 pt6-m mt6-m pt2-l mt2-l`
const ImageContainer2 = tstyled('div')`w-100 tc pt3`
const TourismDecadeLogo = tstyled('img')`w-15 `
const DescButton = tstyled('img')`w-50 w-100-m w-100-l`

function Hero() {
	return (
		<HeroContainer id="hero">
			<ImageContainer>
				<TourismDecadeLogo src="/images/thetourismdecade.png" className="tourism-decade" />
			</ImageContainer>
			{/** SMALL */}
			<HeroRowContainerText>
				<HeroTextH>SEE HOW TOURISM CHANGED THE </HeroTextH>
				<HeroTextH2>LIVES OF LOCALS </HeroTextH2>
			</HeroRowContainerText>
			<ImageContainer2>
			<a href="#section-video" className="img-with-text">
                 <DescButton src="/images/description-button.png" />
				</a>
			</ImageContainer2>
		</HeroContainer>
	)
}

export default Hero
