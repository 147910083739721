import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Whatever work was needed in the kitchen, from dishwashing to serving, Angelo Cayabo eagerly 
did them all. Little did he know that this willingness to learn would one day lead him to 
start his own venture. With just enough money, he decided to leave the employee life and 
took the risk of putting up a travel agency. Watch Angelo as he shares his life as a tour 
boat operator, and how his groundbreaking research on dolphin watching helped boost tourism 
in Puerto Princesa, Palawan.
`

function Carousell() {
	return (
		<>
		<Story heading="THE DOLPHIN WATCHER" description={description} ytID="JTUmzw-bN-I" />
		</>
	)
}

export default Carousell
