import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Dancing is one of the most popular forms of entertainment. But for the T’boli tribe, it means 
so much more. It holds the story of their identity; a reflection of their rich culture, 
heritage, and environment that’s preserved over generations. In this video, you’ll meet Maria 
Todi “Oyog”, founder of Lake Sebu School of Living Tradition. Find out what they have been 
working on to ensure that T’boli traditions will continue to thrive in the face of 
modernization.
`

function Carousell() {
	return (
		<>
		<Story heading="THE DREAMWEAVERS" description={description} ytID="LQK6DkhUbEs" />
		</>
	)
}

export default Carousell
