import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
From a simple fisherman, Bobby Adrao is now a protector of Donsol’s gentle sea giants. 
                As a Butanding Interaction Officer, the certified dive master has led many interactions with 
                whale sharks, educating tourists about the perils these endangered species face every day. 
                And in this video, Bobby shares how protecting the environment helped secure a sustainable 
                livelihood for his family and community.
`

function Carousell() {
	return (
		<>
		<Story heading="BOBBY ADRAO" description={description} ytID="Bo83ycBB7O8" />
		</>
	)
}

export default Carousell
