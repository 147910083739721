import React, { useState } from 'react'
import styled from 'styled-components'
import { carouselData } from './data/carouseldata'
import { RingSpinner } from 'react-spinners-kit'
import useInterval from './utility/useInterval'
import Slide from 'react-reveal/Slide'
import { withReveal } from 'react-reveal'
import device from './device'
import tstyled from 'tachyons-components'

const CarousellContainer = styled.section`
	background: url(${(props) => props.img}) center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	align-items: center;
	width: 100vw;
	overflow: hidden;
	height: 100vh;

	@media screen and ${device.mobileL} {
		object-position: -90vw 20%;
		width: 100%;
		height: 90vh;
	}
`

const Bold = styled.div`
	font-family: 'Barabara';
	z-index: 99;
	color: white;
	font-size: 4.5em;


	@media screen and ${device.mobileL} {
		font-size: 2.5em;
	}
`


const Outline = styled.div`
	font-family: 'Barabara';
	z-index: 99;
	color: white;
	font-size: 4em;

	@media screen and ${device.mobileL} {
		font-size: 3em;
	}
`

// automate positioning with props at carouseldata
/*
const TextHolder = styled.div`
	margin-left: 10vw;
	margin-top: 25vh;
	width: 32%

	@media screen and ${device.mobileL} {
		padding-top: 45%;
	}

	@media screen and ${device.laptopS} {
		zoom: 100%;
	}

    @media screen and ${device.laptopM} {
		zoom: 115%;
	}

    @media screen and ${device.laptopL} {
		zoom: 120%;	
	}

    @media screen and ${device.desktopM} {
		zoom: 140%;
	}
`
*/


const TextHolder = tstyled('div')`
w-30 pt7 ml4 ml5-m pl2-m ml6-l pl6-l
`
/*
const CircleButtonsContainer = styled.div`
	height:3vh;




	@media screen and ${device.mobileL} {
		width: 11%;


	}

	@media screen and ${device.mobileM} {
		max-width: 10%;


	}
`
*/

/*
const CircleButtons = styled.div`
	width: 100%;
	display: flex;
	left: 45vw;
	top: 440vh;
	position:absolute;
	height: 5vw;

	@media screen and ${device.mobileL} {
		display: -webkit-box;
		zoom: 0.7;
		position:relative;
		top:0;


	}
`
*/

const CircleButtons = tstyled('div')`
absolute w-90 w-90-m w-60-l flex flex-row pt3 mt2 pt4-m mt4-m pt5-l pl4 pl7-m ml2-m pl7-l ml7-l ph7-l 
`
const CircleButtonsContainer = tstyled('div')`
w-10 
`
const Boop = tstyled('div')`dn dn-m dn-l`

const Circle = styled.svg`z-index: 99; height:3vh;`
const CircleWrapper = styled.div`position: absolute;height:3vh;`
const CircleWrapper2 = styled.div`width: 60px;position: absolute;height:3vh;`

const MeetButton = styled.button`
	border: none;
	font-family: "Asap";
	background-color: #97b11f;
	margin-top: 20px;
	color: white;
	padding: 3%;
	font-style: italic;
	font-size: 10pt;
	border-radius: 3px;


	@media screen and ${device.mobileL} {
		margin-top: 8px;
		padding: 7%;
	}

	&:hover {
		opacity: 0.7;
		
	}
`


function Carousel() {
	/**
	 * @params { navigate } - sets the position of the carousel
	 */
	const [ navigate, setNavigate ] = useState(0)
	const [ mount, setMount ] = useState(true)

	/**
	 * Changes the pictures every 15 seconds
	 * 1) To edit: +1 to the if statement when adding another person
	 */
	useInterval(() => {
		// Change number of data here
		if (navigate >= 13) {
	
			setNavigate(0)
	
		} else {
			
			setNavigate(navigate + 1)
	
		}
	}, 5000)

	const handClick = (pos) => {
		setMount(false)
		setNavigate(pos)
		setMount(true)
	}

	return  (
			<CarousellContainer id="section-carousel" 
			img={carouselData[navigate].img}
			>
				<TextHolder>
					{/* <Headline>THE</Headline> */}
					<Bold>{carouselData[navigate].bold.toUpperCase()} </Bold>
					{/* <Headline>OF</Headline> */}
					<Outline>{carouselData[navigate].outline.toUpperCase()}</Outline>

					<MeetButton 
					className="animated bounce delay-3s"
					onClick={() => {
						window.location.assign(carouselData[navigate].link)
					}}
					
					>
						{' '}
						Know {carouselData[navigate].gen} story{' '}
					</MeetButton>
				</TextHolder>
				{/* 2) Duplicate this code block and change handclick to the last array element index */}	

				{/**********  START  **********/}
				<CircleButtons className="mobile-circle">
					<CircleButtonsContainer onClick={() => {
						handClick(0)}}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[0]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
				{/********** FINISH **********/}	
				
					
					<CircleButtonsContainer onClick={() => handClick(1)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[1]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(2)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[2]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(3)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[3]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(4)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[4]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(5)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[5]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(6)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[6]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(7)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[7]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(8)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[8]} />
						</CircleWrapper2>
					</CircleButtonsContainer>

					<CircleButtonsContainer onClick={() => handClick(9)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[9]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
					<CircleButtonsContainer onClick={() => handClick(10)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[10]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
					<CircleButtonsContainer onClick={() => handClick(11)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[11]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
					<CircleButtonsContainer onClick={() => handClick(12)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[12]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
					<CircleButtonsContainer onClick={() => handClick(13)}>
						<CircleWrapper>
							<Circle height="40" width="40">
								<circle cx="10" cy="10" r="5" fill="white" />
							</Circle>
						</CircleWrapper>
						<CircleWrapper2>
							<RingSpinner size={20} className="circle-animated" className="circle-animated" className="circle-animated" color="#fff" loading={carouselData[navigate].spin[13]} />
						</CircleWrapper2>
					</CircleButtonsContainer>
				</CircleButtons>

				{/* <CarousellBarStatic src="/images/staticsnaps/profile.png" alt="Carousell" /> */}
			</CarousellContainer>


	) 
}
export default Carousel
