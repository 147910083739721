import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
One of the joys of being an iTravel tour guide is getting to hear and share stories with people from different parts of the globe. That's why Errold Bayona took on the role and made it fun for every tourist in Zamboanga. Watch this video to learn more about his story.
				`

function Carousell() {
	return (
		<>
		<Story heading="ERROLD BAYONA" description={description} ytID="Bs43RjdDJOU" />
		</>
	)
}

export default Carousell
