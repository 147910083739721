import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Eva Valledor wasn’t born a weaver. She was a steadfast civil engineer who struggled hard to 
make her father proud. But working with others gave her a bigger mission in life: to uplift 
not only her family’s life, but as well as the people around her. Watch Eva tell the story 
of how she started Binuatan Creations, and how tourism helped her provide jobs for the people 
of Puerto Princesa, Palawan.				`

function Carousell() {
	return (
		<>
		<Story heading="EVA VALLEDOR" description={description} ytID="N0cb5ENSeBU" />
		</>
	)
}

export default Carousell

