// Data for footers

const footerDataTourism = [
	{
		label: 'About the Philippines',
		link: 'https://tourism.gov.ph/history.aspx'
	},
	{
		label: 'Leisure',
		link: 'https://philippines.travel/'
	},
	{
		label: 'Business',
		link: 'https://tourism.gov.ph/'
	}
]

const footerDataGA = [
	{
		label: 'TIEZA',
		link: 'http://www.tieza.gov.ph/'
	},
	{
		label: 'TPB',
		link: 'http://www.tpb.gov.ph/'
	},
	{
		label: 'NPDC',
		link: 'http://www.npdc.gov.ph/'
	},
	{
		label: 'IA',
		link: 'http://www.intramuros.gov.ph/'
	},
	{
		label: 'DFP',
		link: 'http://www.dfp.com.ph/'
	},
	{
		label: 'PCSSD',
		link: 'http://www.divephilippines.com.ph/'
	},
	{
		label: 'PRA',
		link: 'http://www.pra.gov.ph/'
	}
]

const footerDataSM = [
	{
		label: 'DOT Facebook',
		link: 'https://www.facebook.com/DepartmentOfTourism/'
	},
	{
		label: 'Facebook',
		link: 'https://www.facebook.com/itsmorefuninthePhilippines'
	},
	{
		label: 'Instagram',
		link: 'https://www.instagram.com/tourism_phl'
	},
	{
		label: 'Youtube',
		link: 'https://www.youtube.com/user/morefunph'
	}
]

const footerCountry = [
	// {
	// 	label: ' Australia',
	// 	link: '',
	// 	img: '/images/countries/australia.png'
	// },
	// {
	// 	label: ' China',
	// 	link: '',
	// 	img: '/images/countries/china.png'
	// },
	{
		label: ' Germany',
		link: 'morefunphilippines.de',
		img: '/images/countries/germany.png'
	},
	// {
	// 	label: ' Japan',
	// 	link: '',
	// 	img: '/images/countries/japan.png'
	// },
	// {
	// 	label: ' Korea',
	// 	link: '',
	// 	img: '/images/countries/south-korea.png'
	// },
	{
		label: ' UK',
		link: 'http://www.itsmorefuninthephilippines.co.uk/',
		img: '/images/countries/united-kingdom.png'
	},
	{
		label: ' USA',
		link: 'https://www.philippinetourismusa.com/',
		img: '/images/countries/united-states-of-america.png'
	},
	{
		label: ' Taiwan',
		link: 'http://www.itsmorefuninthephilippines.com.tw/',
		img: '/images/countries/taiwan.png'
	}
]

const footerGA2 =[
	{
		label:'Office of the President',
		link:'http://president.gov.ph/'
	},
	{
		label:'Office of the Vice President',
		link:'http://ovp.gov.ph/'
	},
	{
		label:'Senate of the Philippines',
		link:'http://www.senate.gov.ph/'
	},
	{
		label:'House of Representatives',
		link:'http://www.congress.gov.ph/'
	},
	{
		label:'Supreme Court',
		link:'http://sc.judiciary.gov.ph/'
	},
	{
		label:'Court of Appeals',
		link:'https://ca2.judiciary.gov.ph/caws-war/'
	}
]


export {footerDataTourism, footerDataGA, footerDataSM, footerCountry, footerGA2};