import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
The need to provide often blinds people from the consequences of their actions. And without an alternative source of living, Richard Alyangan knew that illegal environmental practices in Zamboanga would never go away. As a solution to this problem, he pioneered the Yellow Boat Adventure. Watch this video and discover how ecotourism creates more jobs and products, helping communities thrive in a sustainable way.
				`

function Carousell() {
	return (
		<>
		<Story heading="RICHARD ALYANGAN" description={description} ytID="PNdGJQOh_T0" />
		</>
	)
}

export default Carousell
