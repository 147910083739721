import React from 'react'
import styled from 'styled-components'
import device from './device'

const VideoContainer = styled.section`
  min-width: 100%;
  min-height: 100%;
  max-height: 120vh;
  overflow: hidden;

  @media screen and ${device.laptopS} {
		zoom: 100%;
	}

    @media screen and ${device.laptopM} {
		zoom: 115%;
	}

    @media screen and ${device.laptopL} {
      max-height: 100vh;
	}

    @media screen and ${device.desktopM} {
		zoom: 140%;
	}
`

const Vid = styled.iframe`
  width: 100%; 
  height: 800px;
  border: none;

  @media screen and ${device.mobileL} {
    height: 350px;
    margin-bottom: -5px;
  }

  @media screen and ${device.laptopS} {
		zoom: 100%;
	}

    @media screen and ${device.laptopM} {
		zoom: 115%;
	}

    @media screen and ${device.laptopL} {
		    zoom: 120%;
        min-height: 70vh;
	}

    @media screen and ${device.desktopM} {
		zoom: 140%;
	}

`

function Video() {
	return (
		<VideoContainer id="section-video">
        {/* <VideoBarStatic src="/images/staticsnaps/video.png" alt="video" /> */}
        <Vid src="https://www.youtube.com/embed/WJH2iFVTgwc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="promovid" allowfullscreen></Vid>
		</VideoContainer>
	)
}

export default Video;


