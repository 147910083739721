import React from 'react'
import styled from 'styled-components'
import { SphereSpinner } from 'react-spinners-kit'

const LoadingContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #000;
	text-align: center;
	color: #fff;
		display: flex;
`

const Loader = styled.div`

	width: 3vw;
	padding-left: 50vw;
	padding-top: 50vh;
`

function Loading() {
	return (
		<LoadingContainer>
			<Loader>
				<SphereSpinner color="#FFF" />
				<h5>Loading..</h5>
			</Loader>
		</LoadingContainer>
	)
}

export default Loading
