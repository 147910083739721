import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Can a homestay from the far-flung areas of the Philippines be the best in Asia? Belinda Inaw’s humble home just proved that it’s possible when you care just a little bit more. While it is the mystical beauty of the island that lures tourists in, it’s the sincere hospitality and generosity of the people that make them stay. In this video, Belinda shares how she and the other homestay operators worked together to put Siquijor on the map.
`

function Carousell() {
	return (
		<>
		<Story heading="BELINDA INAW" description={description} ytID="njaFYprii2E" />
		</>
	)
}

export default Carousell

