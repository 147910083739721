// data for carousel
// 0-4 index only
// TODO ? Work around to one, two, three data

const carouselData = [
	{
		id: 0,
		outline: '',
		bold: 'The Women Rafters',
		yt: '5JxblVvAs_w',
		img: '/images/carousel/merlyn.jpg',
		spin: [ true, false, false, false, false, false, false, false, false, false, false, false, false, false ],
		link: '/women-rafters',
		gen: 'their'
	},
	{
		id: 1,
		outline: '',
		bold: 'The Community Leader',
		yt: '__wnFq84AgU',
		img: '/images/carousel/eva.jpg',
		spin: [ false, true, false, false, false, false, false, false, false, false, false, false, false, false ],
		link: '/community-leader',
		gen: 'her'
	},
	{
		id: 2,
		outline: '',
		bold: 'The Whaleshark Protector',
		yt: '6w8OzrPvtXA',
		img: '/images/carousel/bobby.jpg',
		spin: [ false, false, true, false, false, false, false, false, false, false, false, false, false, false ],
		link: '/whaleshark-protector',
		gen: 'his'
	},
	{
		id: 3,
		outline: '',
		bold: 'The Dream Weavers',
		yt: 'x1q2laxcvQ4',
		img: '/images/carousel/tboli.jpg',
		spin: [ false, false, false, true, false, false, false, false, false, false, false, false, false, false ],
		link: '/dreamweavers',
		gen: 'their'
	},
	{
		id: 4,
		outline: '',
		bold: 'The Siargao Chef',
		yt: 'Nj5fYFFE1Q',
		img: '/images/carousel/laida.jpg',
		spin: [ false, false, false, false, true, false, false, false, false, false, false, false, false, false ],
		link: '/siargao-chef',
		gen: 'her'
	},
	{
		id: 5,
		outline: '',
		bold: 'The Four-Legged Guide',
		yt: '5JxblVvAs_w',
		img: '/images/carousel/rambo.jpg',
		spin: [ false, false, false, false, false, true, false, false, false, false, false, false, false, false ],
		link: '/four-legged-guide',
		gen: 'its'
	},
	{
		id: 6,
		outline: '',
		bold: 'The River Choir',
		yt: '__wnFq84AgU',
		img: '/images/carousel/bohol.jpg',
		spin: [ false, false, false, false, false, false, true, false, false, false, false, false, false, false ],
		link: '/natures-choir',
		gen: 'their'
	},
	{
		id: 7,
		outline: '',
		bold: 'The Deaf Tour Guides',
		yt: 'dgsaOsBz198',
		img: '/images/carousel/Deaf-tour-guides.jpeg',
		spin: [ false, false, false, false, false, false, false, true, false, false, false, false, false, false ],
		link: '/deaf-tour-guides',
		gen: 'their'
	},
	{
		id: 8,
		outline: '',
		bold: 'The Dolphin Watcher',
		yt: 'dgsaOsBz198',
		img: '/images/carousel/angelo.jpg',
		spin: [ false, false, false, false, false, false, false, false, true, false, false, false, false, false ],
		link: '/dolphin-watcher',
		gen: 'his'
	},
	{
		id: 9,
		outline: '',
		bold: 'The Oldest Tattoo Artist',
		yt: 'EPlMYxaKKCY',
		img: '/images/carousel/whang-od.jpg',
		spin: [ false, false, false, false, false, false, false, false, false, true, false, false, false, false ],
		link: '/oldest-tattoo-artist',
		gen: 'her'
	},
	{
		id: 10,
		outline: '',
		bold: 'The Generous Host',
		yt: 'njaFYprii2E',
		img: '/images/carousel/belinda.JPG',
		spin: [ false, false, false, false, false, false, false, false, false, false, true, false, false, false ],
		link: '/generous-host',
		gen: 'her'
	},
	{
		id: 11,
		outline: '',
		bold: 'The Eco Warrior',
		yt: 'PNdGJQOh_T0',
		img: '/images/carousel/richard.jpeg',
		spin: [ false, false, false, false, false, false, false, false, false, false, false, true, false, false ],
		link: '/eco-warrior',
		gen: 'his'
	},
	{
		id: 12,
		outline: '',
		bold: 'iTravel Tour Guide',
		yt: 'Bs43RjdDJOU',
		img: '/images/carousel/errold.jpeg',
		spin: [ false, false, false, false, false, false, false, false, false, false, false, false, true, false ],
		link: '/itravel-tour-guide',
		gen: 'his'
	},
	{
		id: 13,
		outline: '',
		bold: 'The Changemaker',
		yt: 'Y28RyUtPNRc',
		img: '/images/carousel/eugene.JPG',
		spin: [ false, false, false, false, false, false, false, false, false, false, false, false, false, true ],
		link: '/changemaker',
		gen: 'his'
	}
]

export { carouselData }
