import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = 
`Laida Escoltura saw her home of Cloud Nine transform from a place without proper roads into
a tourist hotspot. Work had been scarce for her and her husband, but with determination and
resourcefulness, she was able to turn her homecooked meals into hearty must-haves for locals
and travelers alike. Watch as Laida talks about her humble beginnings, and how the influx of
visitors helped boost the local economy.`

function Carousell() {
	return (
		<Story heading="LAIDA ESCOLTURA" description={description} ytID="hCsk7xZNPFE" />

	)
}

export default Carousell
