import React from 'react'
import styled from 'styled-components'
import device from './device'

const DescriptionContainer = styled.section`
    position: relative;
    background: url('/images/desc-bg.jpg') no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    max-height: 100%;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    zoom: 100%;

    @media screen and ${device.mobileL} {
        min-height: 70vh;
        background-attachment: scroll;
    }

    @media screen and ${device.laptopS} {
		zoom: 100%;
	}

    @media screen and ${device.laptopM} {
		zoom: 115%;
        min-height: 87vh;
	}

    @media screen and ${device.laptopL} {
		zoom: 135%;
        min-height: 77vh;
	}

    @media screen and ${device.desktopM} {
		zoom: 140%;
	}
`

const Wrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    width: 100%;
    margin-top: 5%;
`

const Desc = styled.p`
    color: white;
    font-family: Asap;
    font-size: 15px;
    padding-left: 37%;
    padding-right: 36%;
    text-align: justify;
	margin-block-start: 0em;
	margin-block-end: 0em;

    @media screen and ${device.mobileL} {   
        font-size: 13px;
        padding-left: 15%;
        padding-right: 15%;
    }
`

const Text = styled.p`
    color: #622a73;
    color: white
    font-family: Asap, sans-serif;
    font-style: italic;
    font-size: 15px;
    margin-block-start: 0em;
    text-align: center;
    margin-top: -40px;
    margin-left: -5px;

    @media screen and ${device.mobileL} {   
        font-size: 13px;
        margin-left: -25px;
    }
` 

const Arrow = styled.img`
    width: 110px;
    position: relative;
    text-align: center;
    align-items: center;
    margin-left: -85px;
    padding-top: 80px;

    @media screen and ${device.mobileL} {   
        margin-left: -95px;
    }
`

function Description() {
    return (
        <DescriptionContainer id="description">
            <Wrapper>
                <Desc> Tourism goes beyond glamorous views and delicious food. It’s also about the lives of 
                        people who make travels more fun in the Philippines.
                </Desc>
                <Desc> The Tourism Decade is a celebration of the tourism industry's achievements, ten years 
                        since Republic Act 9593 was signed into law. Authored by Senator Richard Gordon 
                        together with Congressman Edgar Chatto, RA9593 declared tourism to be an indispensable 
                        element of the national economy, and an industry of national interest and importance.
                </Desc>
                <Desc> Better known as The Tourism Act of 2009, the law highlights the tourism sector’s 
                        transformative power in nation building as it becomes a driving force of empowerment 
                        among Filipinos.
                </Desc><br />
                <Desc>
                    Click <a className="pdflink" href="/Tourism-Act-of-2009-(RA9593).pdf" target="_blank">here</a> to know more about RA9593.
                </Desc><br />
                <Desc>
                    View our collection of inspiring stories from tourism icons from all over the country.
                </Desc>
                <a href="#section-carousel" className="img-with-text">
                    <Arrow src="/images/description-button.png" />
{/*                     <Text>See Stories</Text> */}
                </a>
            </Wrapper>
        </DescriptionContainer>
    )
}

export default Description

