import React from 'react'
import './App.css'
import { Route, Switch, } from "react-router-dom";
import Main from './components/main'
//import Profile from './components/profile'
import Angelo from './components/locals/angelo'
import Bobby from './components/locals/bobby'
import Eva from './components/locals/eva'
import Tboli from './components/locals/tboli'
import Laida from './components/locals/laida'
import Rambo from './components/locals/rambo'
import Choir from './components/locals/choir'
import WomenRafters from './components/locals/womenrafters'
import GenHost from './components/locals/generoushost'
import Ecowarrior from './components/locals/ecowarrior'
//import ComingSoon from './components/locals/comingsoon'
import DeafGuides from './components/locals/deafguides'
import WhangOd from './components/locals/whangod'
import ChangeMaker from './components/locals/changemaker'
import iTravelGuide from './components/locals/errold'
import Redirects from './components/redirects'
import ReactGA from 'react-ga';
import './animate.css'

/**
 * Import to link your file from ./components/locals/{name of file} to a variable in this page. 
 * Check the above imports for examples.
 * TODO: 1. Place your imports below this comment
 */


function App() {
	// Google Analytics link, DO NOT EDIT
	ReactGA.initialize('UA-98095298-19');
	ReactGA.pageview(window.location.pathname + window.location.search);
	
	return (
		<>
		<Switch>
			<Route exact path="/" component={Main} />
			{/*  <Route path="/profile" component={Profile} />  */ }
			<Route path="/dolphin-watcher" component ={Angelo} />
			<Route path="/whaleshark-protector" component ={Bobby} />
			<Route path="/community-leader" component ={Eva} />
			<Route path="/dreamweavers" component ={Tboli} />
			<Route path="/siargao-chef" component ={Laida} />
			<Route path="/four-legged-guide" component ={Rambo}/>
			<Route path="/natures-choir" component ={Redirects} />
			<Route path="/river-choir" component ={Choir} />
			<Route path="/women-rafters" component ={WomenRafters} />
			<Route path="/deaf-tour-guides" component ={DeafGuides} />
			<Route path="/oldest-tattoo-artist" component ={WhangOd} />
			<Route path="/eco-warrior" component ={Ecowarrior} />
			<Route path="/generous-host" component ={GenHost} />
			<Route path="/itravel-tour-guide" component ={iTravelGuide} />
			<Route path="/changemaker" component ={ChangeMaker} />
			{/**
			 * Add a route to your path
			 * @parameters
			 * path: your desired path name
			 * component: the component or js file that has your view
			 * 
			 * 
			 * 2. TODO: Place your route below   
			 * */}

		
        </Switch>
		</>
	)
}

export default App
