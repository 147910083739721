import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Whang-Od started tattooing at age fifteen. Eighty-seven years later, she’s still practicing 
traditional Kalinga tattooing in Buscalan. She and her apprentices carry out the 
thousand-year-old batok tattooing for people who come from far and wide to pay a visit. Watch 
as Whang-Od shares stories from her life, and how the resurgence of Kalinga tattooing effected 
positive change in her community.
				`

function Carousell() {
	return (
		<>
		<Story heading="THE OLDEST TATTOO ARTIST" description={description} ytID="EPlMYxaKKCY" />
		</>
	)
}

export default Carousell