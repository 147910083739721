import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Jamie Aquino believes that the beautiful culture of the Philippines should be accessible to 
                all, particularly to her Deaf community. At that, the Deafinite Tour Guiding Services 
                President masterfully uses sign language to help people like her learn more about the rich 
                history of Intramuros. In this video, Jamie explains how a Department of Tourism initiative 
                has helped build her vision of a more inclusive tourism industry.

				`

function Carousell() {
	return (
		<>
		<Story heading="THE DEAF TOUR GUIDES" description={description} ytID="dgsaOsBz198" />
		</>
	)
}

export default Carousell

