import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
The need to provide often blinds people from the consequences of their actions. And without an alternative source of living, Richard Alyangan knew that illegal environmental practices in Zamboanga would never go away. As a solution to this problem, he pioneered the Yellow Boat Adventure. Watch this video and discover how ecotourism creates more jobs and products, helping communities thrive in a sustainable way.
On your trek to picturesque Batad, expect a four-legged companion by the name of Rambo 
to tour you around. The trusty canine will walk you through centuries-old terraces, 
passing by traditional huts where you can catch stories of folklore from friendly locals. 
Watch as Ramon Binalit of Ramon’s Homestay recalls how his dog became the town’s unofficial 
cultural ambassador and helped preserve the traditions of their Ifugao ancestry.

`

function Carousell() {
	return (
		<>
		<Story heading="RAMBO" description={description} ytID="wq4fjb39w8I" />
		</>
	)
}

export default Carousell
