import React from 'react'
import styled from 'styled-components'
import tstyled from 'tachyons-components'
//import { faYoutube, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {device} from './device';

/*
const Navnav = styled.div`
	width: 100%;
  	padding-left: 15%;
  	padding-right: 15%;
  	padding-top: 5px;
  	position: relative;
  	display: inline-block;
  	vertical-align: top;
  	height: 55px;

	@media screen and ${device.mobileL} {
		padding-left: 0%;
	}

    @media screen and ${device.laptopS} {
		zoom: 100%;
	}

    @media screen and ${device.laptopM} {
		zoom: 115%;
	}

    @media screen and ${device.laptopL} {
		zoom: 130%;
	}

    @media screen and ${device.desktopM} {
		zoom: 140%;
	}
`
*/

const Navnav = tstyled('div')`w-100-m w-100-l mt3-m pl5-m pb3-m pb3-l mt3-l ph7-l pl7-l ml5-l`

const NavContainer = styled.div`
	display: block;

	@media screen and ${device.mobileL} {
		text-align: center;
	}
`

const NavBarBackground = styled.div`
	overflow: hidden;
  	background-color: #1e1e1e;
  	position: absolute; 
  	top: 0; 
  	width: 100%;
	z-index: 10;

	@media and scree ${device.desktop} {
		zoom: 125%;
	}

`

const NavLink = styled.a`
	color: white;
	display: inline-block;
	vertical-align: middle;
  	text-align: center;
	margin-top: -42px;
	margin-left: 5px;
	padding-left: 15px;
  	padding: 23px 12px;
  	text-decoration: none;
	font-family: 'Montserrat', sans-serif;

	&:hover {
		background-color: #30357C;	
		overflow: hidden;
	}

	@media screen and ${device.mobileL} {
        display: none;
    }
`
const ImgLink = styled.a`
	display: inline-block;
	vertical-align: middle;
  	text-align: center;
  	padding: 32px 9px;

	@media screen and ${device.mobileL} {
        display: none;
    }
`

const ALink = styled.a`
	padding-left: .5rem;
	padding-right: .5rem;
	align-self: center;
`

const DOTLogo = styled.img`
	max-width: 150px;
	padding-right: 5px;
	margin-top: -22px;
	padding-top: 20px;

	@media screen and ${device.mobileL} {
        display: inline-block;
		margin-top: 0px;
    	list-style-type: none;
		padding-top: 0px;
    }
`

const DOTLogoB = styled.img`
	max-width: 240px;
	position: relative;
	padding-top: 18px;
	vertical-align: middle;

`
/*
const DOTSocials = styled.a`
	padding-left: 1vw;
	color: #e2e2e2;
	text-decoration: none;
	position: relative;
	top: -2vh;
`
*/


const DOTSocialsContainer = styled.div`
	display: inline-block;

`
function Nav() {
	return (
		<NavBarBackground>
			<NavContainer>
				<Navnav>
			     	<a href="https://philippines.travel/home">
					 	<DOTLogo src="/images/dot-logo-wrb.png" alt="dot-logo" />
					</a>
					<NavLink href="https://philippines.travel/seeanddo">
						<ALink>See and Do</ALink>
					</NavLink>
					<NavLink href="https://philippines.travel/wheretogo"> 
						<ALink>Where to Go</ALink> 
					</NavLink>
					<NavLink href="https://philippines.travel/festivalsandevents"> 
						<ALink>Events and Festivals</ALink>
					</NavLink>
					<DOTSocialsContainer>
						{/* <DOTSocials href="https://www.facebook.com/DepartmentOfTourism/">
							<FontAwesomeIcon className="icons" icon={faFacebookF} />
						</DOTSocials>
						<DOTSocials href="https://www.instagram.com/tourism_phl/?hl=en">
							<FontAwesomeIcon className="icons" icon={faInstagram} />
						</DOTSocials>
						<DOTSocials href="https://www.youtube.com/channel/UC_yCORmIAJntXQDSHs4iFcw">
							<FontAwesomeIcon className="icons" icon={faYoutube} />
						</DOTSocials> */}

						
						{/**
						 * ? its more fun or this microsite
							<ImgLink href="http://www.itsmorefuninthephilippines.com/">
								<DOTLogoB src="/images/dot-button-partoffun.png" alt="bepart" />
							</ImgLink>
					*/}

					
					</DOTSocialsContainer>
				</Navnav>
			</NavContainer>
		</NavBarBackground>
	)
}

export default Nav

/*



*/
