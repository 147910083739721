import React from 'react'
import styled from 'styled-components'
import tstyled from "tachyons-components"
import { footerDataTourism, footerDataGA, footerDataSM, footerCountry } from './data/footerdata'
import device from './device'

const Boops = tstyled('div')`nl5-m pl6-l ml4-l`
// Styles
const FooterContainer = styled.div`
	margin: 0 auto;
    position: relative;
    max-height: 100%;
	background-color: #363636;
    width: 100%;

	@media screen and ${device.mobileL} {
		text-align: center;
	}

`

const FooterLogo = styled.div`
	width: 260px; 
	display: inline-block; 
	padding-top: 2.6%; 
	padding-left: 2%;

	@media screen and ${device.mobileL} {
		${'' /* display: none; */}
		padding-left: 0%;
	}
`

const FooterLogoMobile = styled.div`
	display: none;

	@media screen and ${device.mobileL} {
		width: 260px; 
		display: inline-block; 
		padding-top: 10%; 
	}
`

const FooterAContainer = styled.div`

	font-family: 'Montserrat', sans-serif;
	color: white;
	vertical-align: top;


`

const FooterAColumnContainer = styled.div`
	padding: 1.5%;
	display: inline-block;
	vertical-align: top;
	width: 137px;

	@media screen and ${device.mobileL} {
		align-items: center;
		width: 100%;
		padding-bottom: 2.5%;
	}
`

const FooterCountries = styled.div`
	padding: 1.5%;
	display: inline-block;
	vertical-align: top;
	width: 137px;

	@media screen and ${device.mobileL} {
		text-align: center;
		width: 100%;
		padding-bottom: 10%;
	}
`

const FooterAItem = styled.a`
	color: white;
	text-decoration: none;
	font-size: 14px;
	text-align: left;

	@media screen and ${device.mobileL} {
		align-items: center;
	}

`

const Country = styled.img`
	position: relative;
	top: 1.3vh;

	@media screen and ${device.mobileL} {
		align-items: center;
	}
`

const FooterAICountryCountainer = styled.div`
	display: inline;

	@media screen and ${device.mobileL} {
		text-align: center;
	}
`
const DOTLogo = styled.img`
	width: 160px; 
	padding-left: 25%;

	@media screen and ${device.mobileL} {
		padding-left: 0%;
	}
`

const CR = styled.p`
	font-size: 12pt;
	padding: 0;
	margin: 0;
	padding-left: 40%;	
	position: relative;
	top: -0.5vw;

	@media screen and ${device.mobileL} {
		padding-left: 10%;
	}
`

function Footer() {
	return (
		<>
			{/* Footer A*/}
		
			<FooterContainer>
			<Boops>
				<FooterAContainer>
					<FooterLogo>
						<DOTLogo src="/images/dot-logo-wrb.png" alt="dot-logo" />
						<CR> Copyright 2018 </CR>
					</FooterLogo>
					<FooterAColumnContainer>
						<h4>TOURISM</h4>
						{footerDataTourism.map((items) => (
							<a className="">
								<FooterAItem href={items.link} target="_blank">{items.label}</FooterAItem><br/>
							</a>
						))}
					</FooterAColumnContainer>

					<FooterAColumnContainer>
						<h4>GOVERMENT AGENCIES</h4>
						{footerDataGA.map((items) => (
							<a>
								<FooterAItem href={items.link} target="_blank">{items.label}</FooterAItem><br/>
							</a>
						))}
					</FooterAColumnContainer>

					<FooterAColumnContainer>
						<h4>SOCIAL MEDIA</h4>
						{footerDataSM.map((items) => (
							<a>
								<FooterAItem href={items.link} target="_blank">{items.label}</FooterAItem><br/>
							</a>
						))}
					</FooterAColumnContainer>

					{/* <FooterAColumnContainer>
						<h4>PRIVACY POLICY</h4>
					</FooterAColumnContainer> */}

					<FooterCountries>
						{footerCountry.map((items) => (
							<a className="footer">
								<FooterAICountryCountainer>
									<FooterAItem href={items.link} target="_blank">
										<Country src={items.img} alt={items.label} />
										<a>{items.label}</a>
									</FooterAItem>
								</FooterAICountryCountainer><br/>
							</a>
						))}
						{/* <FooterLogoMobile>
							<DOTLogo src="/images/dot-logo-rainbow.png" alt="dot-logo" />
							<CR> Copyright 2018</CR>
						</FooterLogoMobile> */}
					</FooterCountries>
				</FooterAContainer>
				</Boops>
			</FooterContainer>
		
		</>
	)
}

export default Footer
