import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
The Loboc River Balsa Cultural Performers has brought life to a once sleepy town with their 
cultural concert on a floating raft. The dance-and-song ensemble has entertained thousands 
of foreign and local visitors alike – and in turn, provided a steady source of income for 
its performers. Watch as founder Cleofas Tac-an explains how boosting tourism helped improve 
the lives of his choir members and the children of Loboc.
`

function Carousell() {
	return (
		<>
		<Story heading="THE RIVER CHOIR" description={description} ytID="wCjpQ0x2K2Y" />
		</>
	)
}

export default Carousell

