import React from 'react'
import styled from 'styled-components'
import { footerGA2 } from './data/footerdata'   
import device from './device';
 
const FooterContainer = styled.div`
	margin: 0 auto;
    position: relative;
    max-height: 100%;
    min-height: 40vh;
    width: 100%;
`

const FooterAItem = styled.a`
	color: white;
	text-decoration: none;
	font-size: 16px;
	line-spacing: 4px;
`

const FooterBContainer = styled.div`
	background-color: #31357e;
	font-family: 'Montserrat', sans-serif;
	color: white;
	text-align: center;
`

const FooterBColumnContainer = styled.div`
	padding-left: 1vw;
	display: inline-block;
	font-size: 17px;
	width: 19vw;
	padding: 2%;
	padding-bottom: 6vh;
	vertical-align: top;

	@media screen and ${device.mobileL} {
		width: 90%;
	}
` 

const Pic = styled.img`
	padding: 1.5%;
	vertical-align: middle;
	padding-top: 20vh;

	@media screen and ${device.mobileL} {
		padding-top: 0vh;
	}
`

function FooterB() {
	return (
		<>
            <FooterContainer>
				<FooterBContainer>
				<FooterBColumnContainer>
						<h4>ABOUT GOVPH </h4>
						<FooterAItem>Learn more about the Philippine Government, its structure, how government works and the
						people behind it. All content is in the public domain unless otherwise stated. </FooterAItem><br/><br/>
						<FooterAItem>Trunkline (632) 459
						5200 to 30 </FooterAItem><br/><br/>
						<FooterAItem>Copyright © Department of Tourism 2018</FooterAItem>
				</FooterBColumnContainer>

					<Pic src="/images/f-logo-1.png" alt="f1" />
					<Pic src="/images/f-logo-2.png" alt="f2" />
					<Pic src="/images/f-logo-3.png" alt="f3" />

					<FooterBColumnContainer>
						<h4>GOVERMENT AGENCIES</h4>
						{footerGA2.map((items) => (
							<p>
								<FooterAItem href={items.link}>{items.label}</FooterAItem><br/>
							</p>
						))}
					</FooterBColumnContainer>
				</FooterBContainer>
			</FooterContainer>
        </>
    )
}

export default FooterB