import React from 'react'
import Story from '../templates/tourismdecadestory'

var description = `
Meet Merlyn Montserrat, one of the first women rafters of Lake Pandin, Laguna. Formally 
called the Samahan ng Mga Kababaihang Mangingisda at Bangkera sa Lawa ng Pandin, this group 
of homemakers started their journey with just a single raft and the simple dream of providing 
a better life for their kids. Over a decade later, watch as Merlyn narrates how rafting 
boosted tourism in Lake Pandin, ultimately changing the lives of her family and community for 
the better.
				`

function Carousell() {
	return (
		<>
		<Story heading="WOMEN RAFTERS" description={description} ytID="1lGHOwGOY3Q" />
		</>
	)
}

export default Carousell
